import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { differenceInDays } from 'date-fns'
import { useCustomerStore } from './'
import { graphql } from '@/services/shopify'

import { initSupabase } from '@/stores/supabase'

import type { CustomerAccessToken, CustomerAuthenticationResult, FieldError, CustomerSignup } from '@/types/shopify'

import {
  CustomerAccessTokenCreate,
  CustomerAccessTokenCreateWithMultipass,
  CustomerAccessTokenRenew,
  CustomerCreate,
  CustomerActivate,
  type CustomerAccessTokenCreateQuery,
  type CustomerAccessTokenCreateWithMultipassQuery,
  type CustomerAccessTokenRenewQuery,
  type CustomerCreateQuery,
  type CustomerActivateQuery
} from '@/graphql/mutations'

const log = logger('auth')

export const useAuthStore = defineStore('shopify/auth', () => {
  const customerAccessToken = ref<CustomerAccessToken>()
  const accessToken = computed<string | undefined>(() => customerAccessToken.value?.accessToken)

  const customerStore = useCustomerStore()
  const customer = computed(() => customerStore.customer)

  const handleAuthenticated = async () => {
    const customer = await customerStore.loadCustomer()
    if (!customer) {
      return false
    }

    if (!customer.tags.includes(TAGS.PWA_LOGIN)) {
      // no need to await as this can process in background
      customerStore.tagCustomer([ TAGS.PORTAL_LOGIN, TAGS.PWA_LOGIN ])
    }

    initSupabase(customer.id)

    log('shopify customer loaded')

    return true
  }

  const handleAuthenticationResult = (result: CustomerAuthenticationResult, errors?: FieldError[]) => {
    if (errors?.length) {
      throw new AuthError(errors[0].message)
    }

    if (!result) {
      throw new AppError()
    }

    if (result.customerUserErrors.length) {
      customerAccessToken.value = undefined
      throw new AuthError()
    } else {
      customerAccessToken.value = result.customerAccessToken
    }

    return customerAccessToken.value
  }

  const authenticate = async (email: string, password: string) => {
    const {
      data: { customerAccessTokenCreate },
      errors
    } = await graphql.query<CustomerAccessTokenCreateQuery>({
      query: CustomerAccessTokenCreate,
      variables: {
        input: {
          email,
          password
        }
      }
    })
    return handleAuthenticationResult(customerAccessTokenCreate, errors)
  }

  const signup = async (input: CustomerSignup) => {
    const {
      data: { customerCreate },
      errors
    } = await graphql.query<CustomerCreateQuery>({
      query: CustomerCreate,
      variables: {
        input
      }
    })
    if (errors?.length) {
      throw new GraphqlError(errors[0].message || 'Something went wrong.')
    }
    const { customer, customerUserErrors } = customerCreate
    if (customerUserErrors.length) {
      throw new CustomerError('Could not create account', customerUserErrors)
    }
    if (customer) {
      const { email, password } = input
      const shopifySession = await authenticate(email, password)
      return { customer, shopifySession }
    } else {
      throw new AuthError('Could not create account.')
    }
  }

  const activate = async (variables: any) => {
    const {
      data: { customerActivateByUrl },
      errors
    } = await graphql.query<CustomerActivateQuery>({
      query: CustomerActivate,
      variables
    })
    if (errors?.length) {
      throw new GraphqlError(errors[0].message || 'Something went wrong.')
    }
    const { customerAccessToken, customerUserErrors } = customerActivateByUrl
    if (customerUserErrors.length) {
      throw new CustomerError('Could not activate account', customerUserErrors)
    }
    if (customerAccessToken) {
      return handleAuthenticationResult(customerActivateByUrl)
    } else {
      throw new AuthError('Could not activate account.')
    }
  }

  const authenticateMultipass = async (token: string) => {
    const {
      data: { customerAccessTokenCreateWithMultipass },
      errors
    } = await graphql.query<CustomerAccessTokenCreateWithMultipassQuery>({
      query: CustomerAccessTokenCreateWithMultipass,
      variables: {
        token
      }
    })
    return handleAuthenticationResult(customerAccessTokenCreateWithMultipass, errors)
  }

  const renewAccessToken = async () => {
    const {
      data: { customerAccessTokenRenew: result },
      errors
    } = await graphql.query<CustomerAccessTokenRenewQuery>({
      query: CustomerAccessTokenRenew,
      variables: {
        customerAccessToken: accessToken.value
      }
    })
    if (errors?.length || result?.customerUserErrors?.length) {
      const error = errors?.[0] || result?.customerUserErrors?.[0]
      throw new AuthError(error?.message || 'Could not renew access token')
    } else if (result) {
      customerAccessToken.value = result.customerAccessToken
    }
  }

  const tokenExpiring = () => {
    const { value } = customerAccessToken
    if (value?.expiresAt) {
      const days = differenceInDays(new Date(value.expiresAt), new Date())
      // console.log('shopify token expires in', days, 'days')
      return days < 7
    }
    return false
  }

  const reset = () => {
    customerAccessToken.value = undefined
  }

  return {
    customerAccessToken,
    accessToken,
    customer,
    handleAuthenticated,
    authenticate,
    authenticateMultipass,
    signup,
    activate,
    tokenExpiring,
    renewAccessToken,
    reset
  }
}, {
  persist: [
    {
      pick: [ 'customerAccessToken' ],
      storage: piniaPluginPersistedstate.localStorage()
    },
    {
      pick: [ 'customerAccessToken' ],
      storage: piniaPluginPersistedstate.cookies()
    }
  ]
})
