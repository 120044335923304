import type { RouterConfig } from '@nuxt/schema'
import type { RouterScrollBehavior } from 'vue-router'

const scrollBehavior: RouterScrollBehavior = (to, from, savedPosition) => (savedPosition || { top: 0 })

export default <RouterConfig> {
  scrollBehavior,
  routes: (routes) => {
    return [
      ...routes,
      {
        path: '/subscriptions/new',
        redirect: '/subscriptions/oatmeal/new'
      },
      {
        path: '/subscriptions/:id/edit',
        redirect: to => `/subscriptions/oatmeal/${to.params.id}/edit`
      }
    ]
  }
}
