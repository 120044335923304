import { listBundleSelections } from '@rechargeapps/storefront-client'
import { compareDesc } from 'date-fns'
import type { Session, LineItem, Subscription, Onetime, BundleSelection } from '@rechargeapps/storefront-client'
import { getProp } from './props'
import type { BundleParentProduct, BundleProduct } from '@/types/shopify'
import type { BundleProductSort, BundlePlan } from '@/types/recharge'
import type { RatingProductMap } from '@/stores/supabase'

export const isBundleParentSku = (sku: string | undefined | null) => typeof sku === 'string' && (OATMEAL_BUNDLE_SKU.test(sku) || COFFEE_BUNDLE_SKU.test(sku))
export const isBundleChildSku = (sku: string | undefined | null) => typeof sku === 'string' && BUNDLE_ITEM_SKU.test(sku)
export const getBundleType = (sku: string | undefined | null) => COFFEE_BUNDLE_SKU.test(String(sku)) ? 'coffee' : 'oatmeal'

export const isSingleFlavorItem = (item: LineItem | Subscription | Onetime) => isBundleChildSku(item.sku) && !getProp(item, '_rc_bundle')

export const getPackSize = (string?: string | null) => Number(String(string).match(/8|16|24|32/)?.[0])

export const getBundleSelection = async (session: Session, purchaseItemId: number) => {
  const { bundle_selections } = await listBundleSelections(session, { purchase_item_ids: [ purchaseItemId ], limit: 1 })
  return bundle_selections[0]
}

export const sortFunctions = (favorites: RatingProductMap = new Map()) => ({
  'default': undefined,
  'rating': (a: BundleProduct, b: BundleProduct) => Number(b.ratingAverage?.value || 1) - Number(a.ratingAverage?.value || 0),
  'a-z': (a: BundleProduct, b: BundleProduct) => a.title.localeCompare(b.title),
  'z-a': (a: BundleProduct, b: BundleProduct) => b.title.localeCompare(a.title),
  'release-date': (a: BundleProduct, b: BundleProduct) => compareDesc(new Date(String(a.createdAt)), new Date(String(b.createdAt)).getTime()),
  'favorites': (a: BundleProduct, b: BundleProduct) => {
    const favA = favorites.get(a.sku)
    const favB = favorites.get(b.sku)
    return !favB ? -1 : Number(favB?.rating || 0) - Number(favA?.rating || 0)
  }
})

export const isSortMethod = (sort: string): sort is BundleProductSort => Object.keys(sortFunctions).includes(sort)

export const sumBundleQuantity = (bundle?: BundleSelection) => (bundle?.items.map(i => i.quantity).reduce((a, b) => a + b) || 0)

export const upgradeableBundle = (bundle?: BundleSelection) => (bundle && UPGRADABLE_BUNDLE_SIZES.includes(sumBundleQuantity(bundle)))

export const getBundleSellingPlanId = (product: BundleParentProduct, plan: BundlePlan) => {
  if (plan === 'onetime') {
    return
  }

  const group = product.sellingPlanGroups?.find(g => g.sellingPlans.edges.some(p => p.node.recurringDeliveries))
  return group?.sellingPlans.edges.find(p => p.node.recurringDeliveries)?.node.id
}
