import { ref } from 'vue'
import { defineStore } from 'pinia'

import { listBundleSelections } from '@rechargeapps/storefront-client'
import type { BundleSelection } from '@rechargeapps/storefront-client'
import { useAuthStore } from './auth'

export const useBundleSelectionsStore = defineStore('recharge/bundle-selections', () => {
  const { useSession } = useAuthStore()

  const loading = ref(false)
  const selections = ref<BundleSelection[]>()

  const loadBundleSelections = async (ids: number[]) => {
    loading.value = true
    const response = await listBundleSelections(useSession(), { purchase_item_ids: ids })
    selections.value = response.bundle_selections
    loading.value = false
    return selections
  }

  const selectionExists = (id: number) => selections.value && selections.value.findIndex(i => i.purchase_item_id === id) >= 0

  const useBundleSelections = (ids: number[]) => {
    if (!ids.every(selectionExists) && !loading.value) {
      loadBundleSelections(ids)
    }
    return selections
  }

  const useBundleSelection = (id: number) => {
    if (!selectionExists(id) && !loading.value) {
      loadBundleSelections([ id ])
    }
    return computed(() => findBundleSelection(id))
  }

  const findBundleSelection = (id: number) => selections.value?.find(i => i.purchase_item_id === id)

  const reset = () => {
    selections.value = undefined
    loading.value = false
  }

  return {
    useBundleSelections,
    useBundleSelection,
    findBundleSelection,
    reset,
    selections,
    loading
  }
})
