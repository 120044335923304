import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { compareAsc } from 'date-fns'

import { useBundleProductsStore } from '../shopify'
import { useAuthStore } from './auth'
import { sortRatingsFunctions } from '@/utils/ratings'
import type { SupabaseProductRating } from '@/types/supabase'
import type { BundleType } from '@/types/recharge'

export type RatingProductMap = Map<string, SupabaseProductRating>

export type SortOption = 'a-z' | 'z-a' | 'release-date' | 'favorites' | 'rating' // | 'lowest-calories'

export const useRatingsStore = defineStore('supabase/ratings', () => {
  const auth = useAuthStore()
  const { productMap, findProductBySku } = useBundleProductsStore()
  const { useSupabase } = auth

  const ratings = ref<SupabaseProductRating[]>()
  const sort = ref<SortOption>('favorites')
  const loading = ref(false)
  const ratingType = ref<BundleType>('oatmeal')

  const isCoffee = computed(() => ratingType.value === 'coffee')
  const oatmealRatings = computed(() => ratings.value?.filter(r => r.product_type === 'OATMEAL'))
  const coffeeRatings = computed(() => ratings.value?.filter(r => r.product_type === 'COFFEE'))
  const currentRatings = computed(() => isCoffee.value ? coffeeRatings.value : oatmealRatings.value)

  const load = async () => {
    loading.value = true
    await syncRatings()
    await loadProductsRatings()
    loading.value = false
  }

  const syncRatings = async () => {
    try {
      const { oatbiz } = await useSupabase()
      await oatbiz.put('/api/customer/sync-ratings')
    } catch (e) {
      groupSentrySend('Sync Customer Ratings', e)
    }
  }

  const loadProductsRatings = async () => {
    loading.value = true
    const { supabase, customerId } = await useSupabase()
    const { data, error } = await supabase
      .from('customer_products_ratings_show')
      .select('*')
      .eq('customer_id', customerId)
      .order('rating', { ascending: false, nullsFirst: false })
      .order('ordered_quantity', { ascending: false })
    if (error) {
      return
    }
    ratings.value = data.sort(sortRatingsFunctions(productMap)[sort.value])
    loading.value = false
  }

  const highlightedRatings = computed(() => {
    return !ratings.value
      ? []
      : ratings.value
        .filter(r => !r.rating)
        .filter(r => r.product_type.toLowerCase() === ratingType.value.toLowerCase())
        .sort((a, b) => a.id - b.id)
        .sort((a, b) => compareAsc(new Date(a.order_created_at), new Date(b.order_created_at)))
        .slice(0, 3)
  })

  const useProductsRating = () => {
    if (auth.authenticated && !ratings.value && !loading.value) {
      loadProductsRatings()
    }
    return {
      ratings,
      highlightedRatings,
      ratingProductMap
    }
  }

  const findRatingById = (ratingId: number) => {
    const ratingIdx = ratings.value?.findIndex(i => i.id === ratingId) ?? -1
    if (ratingIdx === -1) {
      throw Error('Product rating not found')
    }
    const productRating = ratings.value?.[ratingIdx]
    return { productRating, ratingIdx }
  }

  const updateNotes = async (ratingId: number, note: string) => {
    try {
      const { supabase } = await useSupabase()
      const { productRating, ratingIdx } = findRatingById(ratingId)
      if (!productRating || productRating.note === note) {
        return
      }
      const { error } = await supabase
        .from('customer_products_notes')
        .insert({
          customer_products_id: ratingId,
          product_id: Number(productRating.product_id),
          customer_id: Number(productRating.customer_id),
          note
        })
      if (error) {
        throw new Error('Error updating rating notes')
      }
      if (ratings.value?.[ratingIdx]) {
        ratings.value[ratingIdx] = { ...productRating, note }
      }
    } catch (e) {
      sendToSentry(e)
    }
  }

  const handleRating = async (ratingId: number, newRating: number) => {
    const { supabase } = await useSupabase()
    const { productRating, ratingIdx } = findRatingById(ratingId)
    if (!productRating || productRating.rating === newRating) {
      return
    }
    const { error } = await supabase
      .from('customer_products_ratings')
      .insert({
        customer_products_id: ratingId,
        product_id: Number(productRating.product_id),
        customer_id: Number(productRating.customer_id),
        rating: newRating
      })
      .select('*')
      .single()

    if (error) {
      throw new Error(`Error rating product\n${error.code}:\n${error.message}`)
    }
    if (ratings.value?.[ratingIdx]) {
      ratings.value[ratingIdx] = { ...productRating, rating: newRating }
    }
  }

  const sortRatings = (newSort: SortOption) => {
    ratings.value?.sort(sortRatingsFunctions(productMap)[newSort])
  }

  const reset = () => {
    ratings.value = []
    sort.value = 'rating'
    loading.value = false
  }

  const ratingProductMap = computed(() => {
    if (ratings.value) {
      const hashMap = new Map<string, SupabaseProductRating>()
      for (const r of ratings.value) {
        hashMap.set(r.sku, r)
      }
      return hashMap
    }
    return undefined
  })

  watch(sort, (newSort: SortOption) => {
    sortRatings(newSort)
  })

  return {
    ratings,
    highlightedRatings,
    sort,
    loading,
    ratingProductMap,
    ratingType,
    isCoffee,
    oatmealRatings,
    coffeeRatings,
    currentRatings,
    load,
    loadProductsRatings,
    useProductsRating,
    findRatingById,
    findProductBySku,
    handleRating,
    sortRatings,
    updateNotes,
    reset
  }
}, {
  persist: {
    pick: [ 'sort' ]
  }
})
