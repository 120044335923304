export const ImageFragment = (width = 2000) => {
  return `id
url(transform: { maxWidth: ${width} })
width
height
altText`
}

export const metaImage = (key: string, width?: number) => `metafield(namespace: "flavor", key: "${key}") {
  value
  reference {
    ... on MediaImage {
      image {
        ${ImageFragment(width)}
      }
    }
  }
}`
