import { ref } from 'vue'
import { defineStore } from 'pinia'

import { getCustomer, type Customer, updateCustomer } from '@rechargeapps/storefront-client'
import { useAuthStore } from './auth'

export const useCustomerStore = defineStore('recharge/customer', () => {
  const customer = ref<Customer>()

  const isFullCustomer = computed(() => !!customer.value?.first_charge_processed_at)
  const hasInvalidPayment = computed(() => customer.value?.has_payment_method_in_dunning)

  const loadCustomer = async () => {
    const { useSession } = useAuthStore()
    customer.value = await fetchWithRetry(() => getCustomer(useSession()))
    return customer.value
  }

  const updateCustomerEmail = async (email: string) => {
    const { useSession } = useAuthStore()
    customer.value = await fetchWithRetry(() => updateCustomer(useSession(), { email }))
    return customer.value
  }

  const useCustomer = () => {
    if (!customer.value) {
      loadCustomer()
    }
    return customer
  }

  const reset = () => (customer.value = undefined)

  return {
    customer,
    isFullCustomer,
    hasInvalidPayment,
    loadCustomer,
    updateCustomerEmail,
    useCustomer,
    reset
  }
})
