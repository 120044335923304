type ScriptOptions = {
  id?: string
  async?: boolean
  defer?: boolean
}

export const appendScript = async (url: string, options: ScriptOptions = {}) => {
  return new Promise((resolve) => {
    const { id } = options
    if (id && document.querySelector(`script#${id}`)) {
      // don't add script if already exists
      return resolve(true)
    }
    const script = document.createElement('script')
    if (id) {
      script.id = id
    }
    script.async = !!options.async
    script.defer = !!options.defer
    script.type = 'text/javascript'
    script.src = url
    script.onload = resolve
    document.body.appendChild(script)
  })
}

export default defineNuxtPlugin(() => {
  const { public: { shopifyUrl } } = useRuntimeConfig()
  appendScript(`https://scripts.juniphq.com/v1/junip_shopify.js?shop=${shopifyUrl}`, { id: 'junip-script', async: true, defer: true })
})
