import { defineStore } from 'pinia'

import { loginShopifyAppProxy, loginWithShopifyStorefront, type Session } from '@rechargeapps/storefront-client'

const log = logger('auth')

export const useAuthStore = defineStore('recharge/auth', () => {
  const { public: { shopifyStorefrontAccessToken } } = useRuntimeConfig()

  const customerStore = rechargeStores.useCustomerStore()
  const customer = computed(() => customerStore.customer)
  const isFullCustomer = computed(() => {
    const { value } = customer
    return value && (value.first_charge_processed_at || value.subscriptions_active_count)
  })

  const session = ref<Session>()

  // called after successful authentication
  const handleAuthenticated = async () => {
    const customer = await customerStore.loadCustomer()
    log('recharge customer loaded')
    return !!customer
  }

  const authenticateProxy = async () => (
    session.value = await fetchWithRetry(() => loginShopifyAppProxy())
  )

  const authenticateAccessToken = async (accessToken: string) => {
    const result = await fetchWithRetry(() => loginWithShopifyStorefront(shopifyStorefrontAccessToken, accessToken))
    if (result?.message && !result.message.startsWith('Success')) {
      sendToSentry(new Error(`loginWithShopifyStorefront: ${result.message}`))
      return session.value = undefined
    }
    return session.value = result || undefined
  }

  const authenticateMultipass = async (data: Session) => {
    const token = await fetchMultipass('recharge', data)
    if (token) {
      session.value = data
    }
    return token
  }

  const signup = async (customer: { shopifyId: string, firstName: string, lastName: string, email: string }) => {
    try {
      return await $fetch('/api/customer/recharge-signup', {
        method: 'POST',
        body: customer
      })
    } catch {
      throw new AuthError('Could not create account')
    }
  }

  const useSession = () => {
    const { value } = session
    if (!value) {
      throw new AuthError()
    }
    return value
  }

  const reset = () => {
    session.value = undefined
  }

  return {
    session,
    customer,
    isFullCustomer,
    handleAuthenticated,
    authenticateProxy,
    authenticateAccessToken,
    authenticateMultipass,
    signup,
    useSession,
    reset
  }
}, {
  persist: {
    pick: [ 'session' ],
    storage: piniaPluginPersistedstate.localStorage()
  }
})
