import { useAuthStore } from './auth'
import { useRatingsStore } from './ratings'

export * from './ratings'
export * from './auth'
export * from './referrals'
export * from './profile'

export const log = logger('supabase')

export const initSupabase = async (customerId: string) => {
  log('init', customerId)
  const auth = useAuthStore()
  await auth.authenticate(customerId)
  if (!auth.authenticated) {
    return
  }
  // wait until auth is complete before we init rating store
  const { load } = useRatingsStore()
  await load()
  log('init complete')
}
