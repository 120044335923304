<template>
  <slot v-if="!error" />
  <slot
    v-else
    name="error"
    :error="error"
    :clear-error="clearError"
  />
</template>

<script setup lang="ts">
const router = useRouter()

const error = ref<Error>()
const emit = defineEmits([ 'error' ])

const clearError = () => {
  error.value = undefined
}

onErrorCaptured((err) => {
  emit('error', err)
  error.value = err
  return false
})

router.beforeEach(() => clearError())
</script>
