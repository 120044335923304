import type { BundleProduct } from '@/types/shopify'
import type { SupabaseProductRating } from '@/types/supabase'

export const sortRatingsFunctions = (products: Map<string, BundleProduct>) => ({
  'a-z': (a: SupabaseProductRating, b: SupabaseProductRating) => (
    String(a.name).localeCompare(String(b.name))
  ),
  'z-a': (a: SupabaseProductRating, b: SupabaseProductRating) => (
    String(b.name).localeCompare(String(a.name))
  ),
  'favorites': (a: SupabaseProductRating, b: SupabaseProductRating) => (
    Number(b.rating) - Number(a.rating)
  ),
  'rating': (a: SupabaseProductRating, b: SupabaseProductRating) => (
    Number(b.ordered_quantity) - Number(a.ordered_quantity)
  ),
  'lowest-calories': (a: SupabaseProductRating, b: SupabaseProductRating) => (
    Number(products.get(a.sku)?.calories?.value)
    - Number(products.get(b.sku)?.calories?.value)
  ),
  'release-date': (a: SupabaseProductRating, b: SupabaseProductRating) => (
    new Date(String(products.get(b.sku)?.createdAt)).getTime()
      - new Date(String(products.get(a.sku)?.createdAt)).getTime()
  )
})
