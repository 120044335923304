import * as Sentry from '@sentry/vue'
import { isAxiosError } from 'axios'

const dsn = String(import.meta.env.VITE_SENTRY_DSN || '')

const IGNORE_REGEX = /(retry count|illegal invocation|request aborted|shopifycloud|monorail|Network Error|Failed to fetch|Object Not Found Matching Id|Hotjar|Load failed|FBNav)/i

export default defineNuxtPlugin(({ vueApp: app, $config: { public: { shopifyUrl } } }) => {
  if (dsn.length) {
    const isProd = shopifyUrl.includes('oats-3')
    const environment = isProd ? 'production' : 'staging'
    Sentry.init({
      app,
      dsn,
      environment,
      beforeSend (event, hint) {
        const error = hint.originalException
        if (error instanceof Error && IGNORE_REGEX.test(String(error?.message))) {
          return null
        } else if (isAxiosError(error) && (error.response?.status || 0) < 500) {
          // ignore 4xx errors
          return null
        } else if (error instanceof AuthError) {
          return null
        }

        return event
      },
      // debug: !isProd,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.httpClientIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          mask: [
            'address'
          ],
          networkDetailAllowUrls: [
            /localhost/,
            // Pwa
            /https:\/\/oats-pwa.vercel.app/, // staging
            /https:\/\/app.oatsovernight.com/, // prod
            // Shopify
            /https:\/\/oatsdev.myshopify.com/, // dev
            /https:\/\/oats-3.myshopify.com/, // prod
            // Recharge
            /https:\/\/api.rechargeapps.com/,
            // Supabase
            /https:\/\/lgblhlvuqnoyvsdhtwuu.supabase.co/, // staging
            /https:\/\/kwquouvcmhpmlvwhhiqs.supabase.co/, // prod
            // Oatbiz
            /https:\/\/staging.oatbiz.com/, // staging
            /https:\/\/app.oatbiz.com/ // prod
          ],
          networkCaptureBodies: true
        })
      ],
      // Performance Monitoring
      tracesSampleRate: 0.005,
      // Session Replay
      replaysSessionSampleRate: 0.002,
      replaysOnErrorSampleRate: 0.01
    })
  }
  return {
    provide: {
      sentry: Sentry
    }
  }
})
