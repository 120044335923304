<template>
  <TransitionRoot
    :show="store.sheet.visible"
    as="template"
  >
    <StackableDialog @close="close">
      <DialogOverlay @click.self="close" />
      <TransitionChild
        as="template"
        enter="duration-200 ease-out"
        enter-from="translate-y-full sm:translate-y-0 sm:translate-x-full"
        enter-to="motion-reduce:transition-none translate-y-0 sm:translate-x-0"
        leave="duration-200 ease-in"
        leave-from="translate-y-0 sm:translate-y-0 sm:translate-x-full"
        leave-to="motion-reduce:transition-none translate-y-full sm:translate-y-0 sm:translate-x-full"
      >
        <DialogPanel
          class="half-sheet absolute bottom-0 left-0 right-0 px-4 py-8 bg-white overflow-y-auto rounded-t-2xl sm:rounded-none z-10 sm:top-0 sm:left-auto sm:w-96"
          :class="content && 'noPadding' in content.props && content.props.noPadding && '!p-0'"
        >
          <CloseButton
            class="absolute right-3 top-3 text-gray-600"
            :class="content && 'hideCloseButton' in content.props && content.props.hideCloseButton && 'hidden'"
            @click="close"
          />
          <Component
            :is="content.component"
            v-if="content"
            v-bind="content.props"
            v-on="content?.events"
          />
        </DialogPanel>
      </TransitionChild>
    </StackableDialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  TransitionRoot,
  TransitionChild,
  DialogPanel
} from '@headlessui/vue'
import { useAppStore } from '@/stores'

const store = useAppStore()
const { content, close } = store.sheet
</script>

<style scoped>
@media screen and (max-width: 639px) {
  .half-sheet {
    max-height: calc(100vh - 64px);
  }
}
</style>
