import { compareDesc } from 'date-fns'
import { sum } from './math'
import { formatSnakeCase } from './string'
import { isBundleParentSku, isBundleChildSku } from './bundles'

import type { LineItem, LineItemBundle, Metafield, RecentOrder, Order } from '@/types/shopify'

export const formatOrderName = (name: string) => name.replace('#', '')

export const formatOrderFulfillmentStatus = (order: RecentOrder | Order) => {
  const { fulfillments } = order
  if (!fulfillments) {
    return 'Pending'
  }
  const statuses = fulfillments.map(i => i.displayStatus)
  if (statuses.length === 1) {
    return formatSnakeCase(statuses[0])
  }
  // Yikes
  if (statuses.every(i => i === 'DELIVERED')) {
    return 'Delivered'
  } else if (statuses.some(i => i === 'DELIVERED')) {
    return 'Partially Delivered'
  } else if (statuses.some(i => i === 'ATTEMPTED_DELIVERY')) {
    return 'Attempted Delivery'
  } else if (statuses.some(i => i === 'IN_TRANSIT')) {
    return 'In Transit'
  } else if (statuses.some(i => i === 'OUT_FOR_DELIVERY')) {
    return 'Out For Delivery'
  } else {
    return 'Shipped'
  }
}

export const formatOrderStatus = (order: RecentOrder | Order) => {
  const {
    status, // if we've already processed the fulfillments to get status
    canceledAt,
    financialStatus,
    fulfillmentStatus
  } = order
  if (status) {
    return status
  } else if (canceledAt) {
    return 'Cancelled'
  }
  switch (financialStatus) {
  case 'REFUNDED':
    return 'Refunded'
  }
  switch (fulfillmentStatus) {
  case 'FULFILLED':
    return formatOrderFulfillmentStatus(order)
  default:
    return 'Pending'
  }
}

export const isOrderTrackable = ({ status }: RecentOrder) => (
  status && ![ 'Cancelled', 'Refunded', 'Delivered' ].includes(status)
)

export const formatOrderDeliveredAt = (order: RecentOrder | Order) => {
  const { fulfillments } = order
  if (!fulfillments) {
    return
  }
  return fulfillments.map(i => i.deliveredAt)
    .filter((i): i is string => typeof i === 'string')
    .sort((a, b) => compareDesc(new Date(a), new Date(b)))
    .pop()
}

const getAttribute = (item: LineItem, attrKey: string) => {
  return item.customAttributes?.find(attr => attr.key === attrKey)?.value
}

const groupBundles = (items: LineItem[]) => {
  const parents = items.filter(item => isBundleParentSku(item.variant?.sku))
  const children = items.filter(item => isBundleChildSku(item.variant?.sku))
  return parents.reduce<LineItemBundle[]>((acc, parent) => {
    const id = String(getAttribute(parent, '_rc_bundle'))
    acc.push({
      parent,
      children: children.filter(i => String(getAttribute(i, '_rc_bundle')) === id)
    })
    return acc
  }, [])
}

export const separateItems = (items: LineItem[]) => {
  const grouped = items.reduce<{ bundle: LineItem[], other: LineItem[] }>((acc, item) => {
    const sku = item.variant?.sku
    if (isBundleParentSku(sku) || (isBundleChildSku(sku) && item.originalTotalPrice.amount === '0.0')) {
      acc.bundle.push(item)
    } else {
      acc.other.push(item)
    }
    return acc
  }, {
    bundle: [],
    other: []
  })
  return {
    bundles: groupBundles(grouped.bundle),
    other: grouped.other
  }
}

export const mapMetaFields = (fields: Metafield[]) => {
  return fields.reduce((acc, value) => {
    // @ts-ignore
    acc[value.key] = value
    return acc
  }, {})
}

export const itemDiscountTotal = (item: LineItem): number => {
  if (isBundleChildSku(item?.variant?.sku)) {
    return 0
  }
  return sum(...item.discountAllocations.map(i => Number(i.allocatedAmount.amount)))
}

export const orderDiscountTotal = (order: Order): number => sum(...order.lineItems.edges.map(i => itemDiscountTotal(i.node)))

export const isCoffeeItem = (item: LineItem) => item.variant && COFFEE_ITEM_SKU.test(item.variant.sku)
